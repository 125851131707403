<template>
    <v-card class="pa-3">
        <h1>{{ $t("systems.title") }}</h1>
        <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            :label="$t('systems.search')"
            single-line
            hide-details
        ></v-text-field>
        <v-data-table 
            :headers="headers" 
            :items="systems" 
            item-key="id" 
            :search="search"
            hide-default-footer
            disable-pagination
        >
            <template v-slot:[`item.isOnline`]="{ item }">
                <v-chip
                    v-if="item.online === 1"
                    color="green lighten-3"
                    light
                >
                    {{ $t("systems.online") }}
                </v-chip>
                <v-chip
                    v-else
                    color="grey lighten-3"
                    light
                >
                    {{ $t("systems.offline") }}
                </v-chip>
            </template>
            <template v-slot:[`item.manage`]="{ item }">
                <v-btn @click="saveSystem(item)">{{$t("systems.manage")}}</v-btn>
            </template>
        </v-data-table>
    </v-card>
</template>

<script>
    import Vue from 'vue'
    import axios from 'axios'
    import { ILLI_API_SERVER_URL } from '../config'
    import {
        mapActions
    } from 'vuex'
    export default Vue.extend({
        title: (context) => context.$t("systems.title"),
        name: "systems",
        components: {
            //
        },
        data () {
            return {
                selectedItem: 0,
                systems: [],
                search: null,
                headers: [
                    {text: this.$t("systems.online"), value: 'isOnline'},
                    {text: this.$t("systems.name"), value: 'name'},
                    {text: this.$t("systems.organisation"), value: 'organisation'},
                    {text: this.$t("systems.location"), value: 'location'},
                    {text: this.$t("systems.department"), value: 'department'},
                    {text: "", value: 'manage'},
                ],
                searchLabel: this.$t("systems.search")
            }
        },
        mounted() {
            axios
                .get(`${ILLI_API_SERVER_URL}/systems`)
                .then(response => {
                    this.systems = response.data.systems.sort(function (a, b) {
                        return a.number - b.number;
                    })
                    if (this.systems.length === 1)
                        this.saveSystem(this.systems[0]);
                })
        },
        methods: {
            ...mapActions({
                chooseSystem: 'system/chooseSystem'
            }),
            saveSystem(system) {
                this.chooseSystem(system).then(() => {
                    this.$router.push({
                        name: 'systemMenu'
                    })
                }).catch(() => {
                    console.log("Failed to store system.")
                })
            }
        }
    })
</script>
